<template>
	<div class="ApiAccounts">
		
		<div class="Top">
			<div class="Left">
				<el-button type="primary" @click="$Jump('/do/api_account/info/add')">添加第三方接口账户</el-button>
			</div>
			<div class="Right">
				<span style="margin-left: 10px;">
					<label>接口账户状态</label>
					<el-select v-model="Search.Status" size="mini">
						<el-option :value="''" label="全部状态"></el-option>
						<el-option :value="50" label="启用中"></el-option>
						<el-option :value="10" label="禁用"></el-option>
					</el-select>
				</span>
				<span style="margin-left: 10px;">
					<label>接口平台</label>
					<el-select v-model="Search.ThirdPlatform" size="mini">
						<el-option :value="''" label="全部平台"></el-option>
						<el-option :label="'苏宁开放平台政企版'" :value="'sn.open.zq'"></el-option>
						<el-option :label="'京东开放平台政企版'" :value="'jd.open.zq'"></el-option>
						<el-option :label="'淘宝开放平台淘宝客版'" :value="'tb.open.tbk'"></el-option>
						<el-option :label="'阿里云通用配置版'" :value="'aliyun'"></el-option>
						<el-option :label="'微信公众号'" :value="'gzh.wx'"></el-option>
						<el-option :label="'微信小程序'" :value="'xcx.wx'"></el-option>
						<el-option :label="'微信支付'" :value="'pay.wx'"></el-option>
						<el-option :label="'微信开放平台'" :value="'open.wx'"></el-option>
						<el-option :label="'支付宝开放平台'" :value="'alipay.open'"></el-option>
						<el-option :label="'极光推送'" :value="'jiguang.push'"></el-option>
						<el-option :label="'快递100'" :value="'kd100'"></el-option>
						<el-option :label="'猫有券开放平台'" :value="'myq.open'"></el-option>
					</el-select>
				</span>
				<span style="margin-left: 10px;margin-right: 10px;">
					<label>接口账户Id</label>
					<el-input size="mini" v-model="Search.Id"></el-input>
				</span>
				<span style="margin-left: 10px;">
					<label>接口名</label>
					<el-input size="mini" v-model="Search.Description"></el-input>
				</span>
				
				<span>
					<label style="color: rgba(0,0,0,0);">搜索</label>
					<el-button size="mini" type="primary" @click="StartSearching()">搜索</el-button>
				</span>
			</div>
		</div>
		
		<div class="List">
			
			<div class="Title">
				<div class="C1">简述</div>
				<div class="C2">平台</div>
				<div class="C3">状态</div>
				<div class="C4">创建时间</div>
				<div class="C5">站内ID</div>
				<div class="C6">操作</div>
			</div>
			
			<div class="One" v-for="item in ApiAccountList" :key="item.Id">
				<li>
					<div class="C1">{{item.Description}}</div>
					<div class="C2">{{item.ThirdPlatformName}}</div>
					<div class="C3">{{item.StatusName}}</div>
					<div class="C4">{{item.CreatedAt}}</div>
					<div class="C5">{{item.Id}}</div>
					<div class="C6">
						<el-button size="mini" @click="$Jump('/do/api_account/info/'+item.Id)">管理</el-button>
					</div>
				</li>
			</div>
			
		</div>
		
		<div class="Page">
			<el-pagination
			  background
			  layout="prev, pager, next"
			  :total="Total" :page-size="PageSize" :current-page="Page" @current-change="handleCurrentChange">
			</el-pagination>
		</div>

	</div>
</template>

<script>
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,Pagination} from 'element-ui'
	export default {
	  name: 'ApiAccountList',
	  props: {
	  },
	  data() {
	      return {
			  Search:{
				  Id:'',
				  Description:'',
				  Status:'',
				  ThirdPlatform:''
			  },
			  ApiAccountList:[],
			  Page:1,
			  PageSize:15,
			  Total:0
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-pagination':Pagination
	  },
	  created() {
	  	this.StartSearching()
	  },
	  methods:{
		  handleCurrentChange(val){
			 this.GetList(val)
		  },
		  StartSearching(){
			  this.GetList(this.Page)
			  this.GetCount()
		  },
		  GetList(_page){
		  		let data = {
		  			Service:'ApiAccount',
		  			Class: 'ApiAccount',
		  			Action: 'List',
		  			Page:_page,
		  			PageSize:this.PageSize,
					Id:this.Search.Id,
					Description:this.Search.Description,
					Status:this.Search.Status,
					ThirdPlatform:this.Search.ThirdPlatform
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){this.$message(res.Msg);return;}
					this.ApiAccountList = res.Data.ApiAccountList
					this.Page = res.Data.CurrentPage
		  			
		  		})
		  		.catch(function (response) {
					this.$message('网络请求错误')
		  		})
		  },
		  GetCount(){
		  		let data = {
		  			Service:'ApiAccount',
		  			Class: 'ApiAccount',
		  			Action: 'Count',
					Id:this.Search.Id,
					Description:this.Search.Description,
					Status:this.Search.Status,
					ThirdPlatform:this.Search.ThirdPlatform
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){this.$message(res.Msg);return;}
		  					this.Total = res.Data.Count
		  			
		  		})
		  		.catch(function (response) {
		  					this.$message('网络请求错误')
		  		})
		  }
	  }
	}
</script>

<style scoped>
.ApiAccounts{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.ApiAccounts .Top{
	display: flex;
	align-items: center;
	border-bottom: 1px solid rgba(0,0,0,0.05);
	padding-bottom: 20px;
}
.ApiAccounts .Top .Left{
	
}
.ApiAccounts .Top .Right{
	flex: 1;
	display: flex;
	color: #999999;
	justify-content: flex-end;
}
.ApiAccounts .Top .Right span{
	margin-left: 10px;
}
.ApiAccounts .Top .Right label{
	display: block;
	font-size: 0.9rem;
	color: rgba(0,0,0,0.4);
	margin-bottom: 5px;
}
.ApiAccounts .List{
}
.C1,.C2,.C3,.C4,.C5{
	width: 150px;
}
.C2{
	width: 250px;
}
.C5{
	width: 250px;
	color: rgba(0,0,0,0.4);
}
.C1{
	padding-left: 10px;
}

.C6{
	width: 100px;
	text-align: right;
	padding-right: 10px;
}
.C1{
	flex: 1;
}
.C3{
	width: 80px;
}
.C4{
	width: 100px;
	color: rgba(0,0,0,0.4);
}
.ApiAccounts .List .Title{
	display: flex;
	margin-bottom: 20px;
	color: rgba(0,0,0,0.3);
	padding-bottom: 10px;
	padding-top: 10px;
	border-bottom: 1px solid rgba(0,0,0,0.04);
	font-size: 0.9rem;
}
.ApiAccounts .List .One{
	border-bottom: 1px solid rgba(0,0,0,0.04);
	padding: 10px 0px;
}
.ApiAccounts .List .One:hover{
	background-color: rgba(228,0,0,0.1);
}
.ApiAccounts .List .One li{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
</style>
